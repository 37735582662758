<template>
    <div class="loginView">
        <div class="loginContainer col-6 row gx-0 justify-content-end">
            <div class="col-8 align-self-center">
                <h1 class="">{{ $t('login.renewPassword') }}</h1>
                <b-form @submit="forgotPassword">
                    <b-form-group>
                        <input :placeholder="$t('login.forgotPasswordEmailPlaceholder')" type="email" v-model="email" class="form-control">
                        <div class="row justify-content-between">
                            <div class="passwordReset col align-self-center">
                                <router-link to="/login">
                                    {{ $t('login.backToLogin') }}
                                </router-link> 
                            </div>    
                            <div class="col">
                                <b-button class="loginButton" type="submit" variant="primary">{{ $t('login.submitPasswordForgot') }}</b-button>
                            </div>    
                        </div>    
                    </b-form-group>
                    <div class="spacer l"/>
                    <p v-show="done" class="">
                        <i18n path="login.linkWasSend" >
                            <template v-slot:userEmail>
                                {{ email }} 
                            </template>
                        </i18n>
                    </p>
                    <p v-show="error" class="error">{{ $t('general.errorHasOccured') }}</p>
                    <p class="error" v-if="errorMsg != ''">{{ errorMsg }}</p>
                </b-form>
            </div>    
        </div> 
        <div class="logoContainer col-6">
            <img src="../assets/img/logo_pooling.svg">
        </div>
    </div>
</template>
<script>
    import DataService from '@/services/StrapiService'
    export default {
        name: 'ForgotPassword',
    
        data() {
            return {
                email: '',
                done: false,
                error: false,
                errorMsg: '',
                strapiUrl: DataService.getServerUrl()
            }
        },
        methods: {
            async forgotPassword(e) {
                e.preventDefault()
                this.done = false;
                this.error = false;
                this.axios.post(this.strapiUrl+'/auth/forgot-password', {
                    email: this.email
                })
                .then(() => {
                    this.done = true
                })
                .catch(error => {
                    console.log(error)
                    this.error = true
                    if (error.response != null && error.response['data'].data != null) {
                      var serverMessage = error.response['data'].data.message[0].messages[0].message
						this.errorMsg = serverMessage
                      if (serverMessage === "This email does not exist.") {
                        this.errorMsg = this.$t('login.errorEmailNotExist')
                      }
                      if (serverMessage === "Please provide a valid email address.") {
                        this.errorMsg = this.$t('login.errorFalseEmail')
                      }
                    }
                })
            }
        }
    }
</script>
<style scoped>
</style>